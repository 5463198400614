import React from "react";

function SearchPage(props) {
  return (
    <section>
      <form>
        <div className="form-field">
          <input type="search" placeholder="Search for anything" />
        </div>
      </form>
    </section>
  );
}

export default SearchPage;
