import React from "react";

function Dashborad(props) {
  // const token = localStorage.getItem("authToken");
  // console.log(token);
  return (
    <section>
      <h1>Welcome to my dashboard</h1>
    </section>
  );
}

export default Dashborad;
